<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Service Settings</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Registration mode</p>
                    <p class="mb-0 pb-0">
                        <span>{{ registrationModeText }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditRegistrationMode = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditRegistrationMode" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Edit registration mode</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>There are 4 modes available:</p>
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Open registration</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up to create new accounts and manage realms within their accounts. This is a good setting for a production SaaS deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Approval required</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Self-service registration is available and people can sign up, but all requests are placed in a queue to be approved by an administrator. The accounts are not created without approval. This is a good setting for a "public beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>By invitation only</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed and people can only create accounts with an invitation. Only service administrators can send invitations. This is a good setting for a "private alpha" or "private beta" deployment or an enterprise deployment.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Closed</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <p>Registration is closed. Service administrators can still create new accounts and realms directly, but new administrator accounts cannot be created. This is the default setting.</p>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-form @submit.prevent="editRegistrationMode" @keyup.enter.native="editRegistrationMode" class="mt-5">
                                    <v-select
                                        v-model="editableRegistrationMode"
                                        :items="registrationModeChoices"
                                        label="Registration mode"
                                        outlined>
                                    </v-select>
                                    <v-card-actions>
                                        <v-row justify="center">
                                        </v-row>
                                    </v-card-actions>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editRegistrationMode" :disabled="!isEditRegistrationModeFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditRegistrationMode = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <p class="text-overline mb-0">Dynamic DNS</p>
                    <p class="mb-0 pb-0">
                        <span>Shared domain holding account: {{ dynamicSharedDomainHoldingAccountName }}</span>
                        <v-btn class="blue--text no-print" @click="dialogEditSharedDomainHoldingAccount = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditSharedDomainHoldingAccount" max-width="600">
                        <v-card elevation="4">
                            <v-toolbar flat color="white">
                                <v-toolbar-title>Edit holding account for Dynamic DNS shared domains</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="pa-5">
                                <p>There can only be ONE holding account for Dynamic DNS shared domains.</p>
                                <p>Generally this setting should NOT be changed after its initial setting.</p>
                                <v-form @submit.prevent="editDynamicSharedHoldingAccount" @keyup.enter.native="editDynamicSharedHoldingAccount" class="mt-5">
                                    <v-text-field
                                        v-model="editableSharedDomainHoldingAccountId"
                                        label="Account ID"
                                        outlined>
                                    </v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editDynamicSharedHoldingAccount" :disabled="!isEditDynamicSharedDomainHoldingAccountFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSharedDomainHoldingAccount = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableRegistrationMode
                                color="blue"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editRegistrationMode" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="blue"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
// import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';

export default {
    data: () => ({
        isViewReady: false,

        // generic form controls
        formSubmitTimestamp: null,

        // registration mode
        registrationMode: 'closed',
        dialogEditRegistrationMode: false,
        editableRegistrationMode: null,
        registrationModeChoices: [
            { text: 'Open registration', value: 'open' },
            { text: 'Approval required', value: 'open_moderated' },
            { text: 'By invitation only', value: 'invitation' },
            { text: 'Closed', value: 'closed' },
        ],
        // dynamic dns
        dialogEditSharedDomainHoldingAccount: false,
        dynamicSharedDomainHoldingAccount: null,
        editableSharedDomainHoldingAccountId: null,
    }),
    computed: {
        registrationModeText() {
            return this.registrationModeChoices.find((item) => item.value === this.registrationMode)?.text ?? `Unknown (${this.registrationMode})`;
        },
        dynamicSharedDomainHoldingAccountName() {
            return this.dynamicSharedDomainHoldingAccount?.name ?? '';
        },
        isEditRegistrationModeFormComplete() {
            return typeof this.editableRegistrationMode === 'string' && this.editableRegistrationMode !== this.registrationMode;
        },
        isEditDynamicSharedDomainHoldingAccountFormComplete() {
            return typeof this.editableSharedDomainHoldingAccountId === 'string' && this.editableSharedDomainHoldingAccountId !== this.dynamicSharedDomainHoldingAccount?.id;
        },
    },
    watch: {
        dialogEditRegistrationMode(value) {
            if (value) {
                this.editableRegistrationMode = this.registrationMode;
            }
        },
        dialogEditSharedDomainHoldingAccount(value) {
            if (value) {
                this.editableSharedDomainHoldingAccountId = this.dynamicSharedDomainHoldingAccount?.id;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemNameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async editServiceConfig(name, content) {
            try {
                this.$store.commit('loading', { editServiceConfig: true });
                const response = await this.$client.main().service.editSetting({ name, content });
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Saved changes' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                }
                return response?.isEdited ?? false;
            } catch (err) {
                console.error('failed to edit setting', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to save changes' });
                return false;
            } finally {
                this.$store.commit('loading', { editServiceConfig: false });
                this.isViewReady = true;
            }
        },
        async editRegistrationMode() {
            if (Number.isInteger(this.formSubmitTimestamp) && this.formSubmitTimestamp + 500 > Date.now()) {
                return;
            }
            this.formSubmitTimestamp = Date.now();
            const isEdited = await this.editServiceConfig('registration_mode', this.editableRegistrationMode);
            if (isEdited) {
                this.registrationMode = this.editableRegistrationMode;
            }
            this.dialogEditRegistrationMode = false;
        },
        async editDynamicSharedHoldingAccount() {
            if (Number.isInteger(this.formSubmitTimestamp) && this.formSubmitTimestamp + 500 > Date.now()) {
                return;
            }
            this.formSubmitTimestamp = Date.now();
            const isEdited = await this.editServiceConfig('dynamic_shared_domain_holding_account_id', this.editableSharedDomainHoldingAccountId);
            if (isEdited) {
                this.loadDynamicSharedDomainHoldingAccount(this.editableSharedDomainHoldingAccountId);
            }
            this.dialogEditSharedDomainHoldingAccount = false;
        },
        async loadServiceSettingsList() {
            try {
                this.$store.commit('loading', { loadServiceSettingsList: true });
                const response = await this.$client.main().service.getSettingsList();
                if (response?.list) {
                    const settingsMap = {};
                    response.list.forEach((item) => {
                        settingsMap[item.name] = item.content;
                    });
                    this.registrationMode = settingsMap.registration_mode ?? 'closed';
                    if (settingsMap.dynamic_shared_domain_holding_account_id) {
                        this.loadDynamicSharedDomainHoldingAccount(settingsMap.dynamic_shared_domain_holding_account_id);
                    } else {
                        this.dynamicSharedDomainHoldingAccount = null;
                    }
                }
            } catch (err) {
                console.error('failed to load service settings', err);
            } finally {
                this.$store.commit('loading', { loadServiceSettingsList: false });
                this.isViewReady = true;
            }
        },
        async loadDynamicSharedDomainHoldingAccount(accountId) {
            try {
                this.$store.commit('loading', { loadDynamicSharedDomainHoldingAccount: true });
                this.dynamicSharedDomainHoldingAccount = await this.$client.account(accountId).currentAccount.get();
            } catch (err) {
                console.error('failed to load dynamic dns shared domain holding account', err);
            } finally {
                this.$store.commit('loading', { loadDynamicSharedDomainHoldingAccount: false });
            }
        },
    },
    mounted() {
        this.loadServiceSettingsList();
    },
};
</script>
